<div class="container">
  <div class="header">
      <h1 class="bignumber">ChangeLog</h1>
  </div>
  <div class="logs">
    <div class="change" *ngFor="let log of log_data">
      <hr>
      <h6>{{log.date}}</h6>
      <h3>{{log.version}}</h3>
      <ul>
        <li *ngFor="let change of log.changes">{{change}}</li>
      </ul>
    </div>
  </div>
  <ion-button
      size="large"
      color="danger"
      (click)="dismiss()"
  >
      Volver
  </ion-button>
</div>