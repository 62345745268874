import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { CHANGELOG_DATA } from './changelog';
@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss'],
})
export class ChangelogComponent implements OnInit {

  log_data = [];

  constructor(private modalController: ModalController) {
  }

  ngOnInit() {
    this.log_data = CHANGELOG_DATA.reverse();
    console.log(this.log_data);
  }

  dismiss(){
    this.modalController.dismiss();
  }

}
