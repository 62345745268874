import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LocalOnlyPreloadingStrategy } from './localOnlyPreloadin';
import { authGuard } from './guards/auth.guard';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./auth/password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
  },
  { path: 'details',
    loadChildren: () => import('./overall-results/overall-results.module').then( m => m.OverallResultsModule ),
    canActivate: [authGuard],
  },

  {
    path: 'semaforo_test',
    loadChildren: () => import('./semaforo/semaforo_test/semaforo_test.module').then( m => m.Semaforo_testPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'laberinto_test',
    loadChildren: () => import('./laberinto/laberinto_test/laberinto_test.module').then( m => m.Laberinto_testPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'encuesta',
    loadChildren: () => import('./encuesta/encuesta.module').then( m => m.EncuestaPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'encuestafin',
    loadChildren: () => import('./encuestafin/encuestafin.module').then( m => m.EncuestafinPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'bloqueo',
    loadChildren: () => import('./bloqueo/bloqueo.module').then( m => m.BloqueoPageModule),
    // canActivate: [authGuard],
  },
  {
    path: 'fatiga_test',
    loadChildren: () => import('./fatiga/fatiga_test/fatiga_test.module').then( m => m.FatigaTestPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'alcoholimetro',
    loadChildren: () => import('./alcolimetro/alcolimetro-routing.module').then( m => m.AlcolimetroPageRoutingModule),
    canActivate: [authGuard],
  },
  {
    path: 'formulario-registro',
    loadChildren: () => import('./formulario-registro/formulario-registro.module').then( m => m.FormularioRegistroPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'test-score',
    loadChildren: () => import('./test-score/test-score.module').then( m => m.TestScorePageModule),
    canActivate: [authGuard],
  },
  {
    path: 'test-overview',
    loadChildren: () => import('./test-score/test-overview/test-overview.module').then( m => m.TestOverviewPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'registro-email',
    loadChildren: () => import('./registro-email/registro-email.module').then( m => m.RegistroEmailPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'recomendacion-activa',
    loadChildren: () => import('./recomendacion-activa/recomendacion-activa.module').then( m => m.RecomendacionActivaPageModule),
    canActivate: [authGuard],
  },
  {
    path: 'test2',
    loadChildren: () => import('./test2/test2.module').then( m => m.Test2PageModule),
    canActivate: [authGuard],
  },
  {
    path: 'test3',
    loadChildren: () => import('./test3/test3.module').then( m => m.Test3PageModule),
    canActivate: [authGuard],
  },
  {
    path: 'alcolimetro',
    loadChildren: () => import('./alcolimetro/alcolimetro.module').then( m => m.AlcolimetroPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: LocalOnlyPreloadingStrategy  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
