import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { CameraPreview } from '@awesome-cordova-plugins/camera-preview/ngx';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HttpClientModule} from  '@angular/common/http';
import { BackgroundMode } from '@awesome-cordova-plugins/background-mode/ngx';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';
import { NetworkInterface } from '@awesome-cordova-plugins/network-interface/ngx';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { NativeAudio } from '@awesome-cordova-plugins/native-audio/ngx';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { PermissionComponent } from './auth/login/modal/permission/permission.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment'
import { VideoComponent } from './utils/modal/video/video.component';
import { ChangelogComponent } from './utils/modal/changelog/changelog.component';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
@NgModule({
    declarations: [AppComponent, PermissionComponent, VideoComponent, ChangelogComponent],
    imports: [
        BrowserModule,
        FormsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        RouterModule,
        CommonModule,
        ReactiveFormsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })],
    providers: [
        StatusBar,
        SplashScreen,
        Geolocation,
        Camera,
        CameraPreview,
        HTTP,
        BackgroundMode,
        AndroidPermissions,
        Diagnostic,
        NetworkInterface,
        Storage,
        NativeAudio,
        AndroidPermissions,
        ScreenOrientation,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        defineCustomElements(window);
    }
}