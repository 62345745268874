export const CHANGELOG_DATA = [
    {
        version: '1.6.1',
        date: "6/04/2023",
        changes: [
            "Se cambió la rotulación de etapas (Slider-Laberinto-Botones)",
            "Ahora se reproduce un video de pausa Activa al obtener un Test de Alto Riesgo.",
            "Cambio en el comportamiento de la ventana resumen final, al presionar el botón te lleva a la pantalla de bloqueo en vez de volver al login."
        ]
    },
    {
        version: '1.6.2',
        date: "12/04/2023",
        changes: [
            "Se oculto video de pausa activa.",
            "Se agrego mensaje al obtener Alto riesgo solo para dispositivos Tablet"
        ]
    },
    {
        version: '1.6.3',
        date: "14/04/2023",
        changes: [
            "Se incluye un flujo para que el usuario pueda recuperar su contraseña.",
            "Nueva pagina para pedir Email de forma obligatoria si el usuario no tiene uno asignado.",
            "Nueva pagina para recuperar contraseña.",
            "Corrección en la función \"Login\"; aparentemente expresaba de forma errónea que el usuario no tenia conexión a internet. Ahora muestra correctamente que el usuario o contraseña no son los correctos."
        ]
    },
    {
        version: '1.6.4',
        date: "14/04/2023",
        changes: [
            "Se incluye mensaje de error al levantar el dedo y llegar al ultimo checkpoint sin haber seguido el camino correcto en\"TEST LABERINTO\".",
            "Se agrega numero de version y ventana descriptiva con los cambios históricos de la aplicación.",
            "Se agrego un redireccionador automático al login, luego de haber recuperado la contraseña.",
            "Ahora se muestra el mensaje de error correspondiente en caso de no poder cambiar contraseña."
        ]
    },
    {
        version: '1.6.5',
        date: "21/04/2023",
        changes: [
            "Se agrega nueva pantalla de Bienvenido y acceso directo a bases científicas de los tests.",
            "Se agrega link para crear nuevos usuarios en la pantalla de login",
            "Se agrega nueva pantalla al final de la encuesta de sugerencia o pausa activa.",
            "Se agrega nuevo video de pausa activa."
        ]
    },
    {
        version: '1.6.6',
        date: "21/04/2023",
        changes: [
            "Se corrige mensaje erróneo al intentar loguear con un usuario que no existe en el sistema en logueo por movil."
        ]
    },
    {
        version: '1.6.7',
        date: "26/04/2023",
        changes: [
            "Se unen las pantallas de Etapa de Test en una sola, para minimizar la cantidad de Clicks.",
        ]
    },
    {
        version: '1.6.8',
        date: "26/04/2023",
        changes: [
            "Actualización de version en el software interno de la Aplicación.",
            "Optimización general de la Aplicación."
        ]
    }
]