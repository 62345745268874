import { Injectable, inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TestService } from '../api/test.service';

export const authGuard: CanActivateFn = (route, state) => {

  const testService = inject(TestService);
  const router = inject(Router);


  if(!testService.usuario || (testService.is_test_finished && testService.current_env === 'totem')) {
    router.navigate(['/login']);
    return false;
  }

  return true;
};
