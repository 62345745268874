import { Injectable } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class LocalOnlyPreloadingStrategy implements PreloadingStrategy {
    constructor(private platform: Platform) { }
    public preload(route: Route, fn: () => Observable<boolean>): Observable<boolean> {
        if (false) {
            // Running on a device
            return fn();
        }
        // Not running on a device
        return of(false);
    }
}